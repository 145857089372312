@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.chevron {
  background: rgba(255, 255, 255, 0.1);
}

.HomePageContainer {
  width: 100%;
  float: left;
  padding: 10px 0;
}

.SeassonsCardContainer {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 10px 0;
}

.SeassonsNameYear {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(0 0 0 / 45%);
}

.SeassonsNameYear i {
  font-size: 10px;
  margin: 0 5px;
}

.SeassonsYear {
  color: rgb(0 0 0 / 85%);
  font-weight: 700;
}

.SeassonsPlace {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #000000;
  line-height: 147.5%;
}

.SeassonsCardButtonContainer {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.SeassonsCardButtonContainer .ant-btn {
  font-size: 13px;
  line-height: 22px;
  color: rgb(0 0 0 / 85%);
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  height: auto !important;
  padding: 2px 8px;
}

.SeassonsCardButtonContainer .ant-btn:hover,
.SeassonsCardButtonContainer .ant-btn:active,
.SeassonsCardButtonContainer .ant-btn:focus {
  color: #ac0000;
  border-color: #ac0000;
}

.PlayerFilter {
  width: 100%;
  float: left;
  margin: 10px 0;
  padding: 10px 0;
}

.PlayerFilterTitle {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}

.PlayerFilterForm {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}

.PlayerFilterForm .ant-col.ant-form-item-label {
  height: auto;
  line-height: 0;
}

.PlayerFilterForm .ant-col.ant-form-item-label > label {
  height: auto;
  line-height: 0;
  font-size: 12px;
  line-height: 10px;
}

.PlayerFilterForm .ant-col.ant-form-item-control .ant-input {
  border: 1px solid #d9d9d9;
  height: auto !important;
  padding: 4px 8px;
  border-radius: 2px;
}

.PlayerFilterForm .ant-form-item-control-input-content .ant-btn {
  width: 100%;
  float: left;
  font-size: 12px;
  height: auto;
  padding: 6px 10px;
  margin-top: 6px;
  color: rgb(0 0 0 / 85%);
}
.PlayerFilterForm.ant-form-vertical .ant-form-item {
  margin-bottom: 0;
}

.PlayerFilterForm .ant-form-item-control-input-content .ant-btn:hover,
.PlayerFilterForm .ant-form-item-control-input-content .ant-btn:active,
.PlayerFilterForm .ant-form-item-control-input-content .ant-btn:focus {
  color: #fff;
  border-color: #ac0000;
  background-color: #ac0000;
}

.PlayerFilterForm
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  border: none !important;
}
.PlayerprimaryCard {
  width: 100%;
  float: left; 
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  margin: 10px 0;
  border: solid 1px #fff;
  transition: all ease 0.3s;
  cursor: pointer;
}
.PlayerprimaryCard label {
  color: #000 !important;
  font-size: 14px !important;
}
.primaryCard {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  margin: 10px 0;
  border: solid 1px #fff;
  transition: all ease 0.3s;
  cursor: pointer;
}
.primaryCard.active,
.primaryCard:hover {
  border-color: #fff;
  transition: all ease 0.3s;
}
.userInfoHeader {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}
.UserNameLine .eyes {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  margin-bottom: 15px;

  /* Red */

  color: #a40e17;
}
.UserNameLine .eyes .anticon {
  margin-right: 2px;
  position: relative;
  bottom: -1px;
  font-size: 12px;
}
.Soph {
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  background-color: #c12730;
  color: #fff;
  font-size: 9px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  line-height: 12px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.SophNo {
  font-size: 22px;
  font-weight: 900;
  line-height: 22px;
}
.CardUserImg {
  width: 67px;
  height: 67px;
  border-radius: 100%;
  margin: 0 10px;
}
.UserNameLine {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
}
.CardName {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Gray */
  margin-bottom: 10px;
  color: #2e4d6b;
}
.CardNameBold {
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  color: #0f1a24;
}
.CardNameLine {
  width: 100%;
  float: left;
  font-size: 11px;
  line-height: 11px;
  color: #607d8b;
  margin-top: 5px;
}
.ExecutedSwitchContainer {
  width: 100%;
  float: left;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin-top: 22px;
}
.ExecutedSwitch {
  width: 100%;
  float: left;
  padding: 18px 13px;
  font-size: 12px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
}
.ExecutedSwitch:last-child {
  border-bottom: none;
}
.PrimaryCardForm {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}
.PrimaryCardForm.ant-form .ant-form-item-label > label {
  font-size: 12px;
  color: #000;
  height: 20px;
}
.PrimaryCardForm.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
  min-width: 67px;
  text-align: left;
}
.PrimaryCardForm.ant-form-horizontal .ant-select {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
}
.PrimaryCardForm.ant-form-horizontal .ant-form-item-label > label::after {
  display: none;
}
.PrimaryCardForm.ant-form-horizontal .ant-form-item {
  margin-bottom: 10px;
}
.PrimaryCardForm .ant-btn-primary {
  border-color: #1890ff;
  background: #1890ff;
}
.PrimaryCardFormEmojiCheck.ant-form label {
  font-size: 24px;
}
.PrimaryCardFormEmojiCheck .SmileEmoji {
  color: #2de4ad;
  font-size: 24px;
  width: 22px;
  height: 22px;
}
.PrimaryCardFormEmojiCheck .CryEmoji {
  color: #c12730;
  font-size: 24px;
  width: 22px;
  height: 22px;
}
.PrimaryCardFormEmojiCheck .ant-checkbox + span {
  display: flex;
  align-items: center;
}
.PrimaryCardFormEmojiCheck .ant-checkbox-inner {
  display: none;
}
.PrimaryCardFormEmojiCheck .ant-checkbox + span .SmileEmoji {
  display: none;
}
.PrimaryCardFormEmojiCheck
  .ant-checkbox.ant-checkbox-checked
  + span
  .SmileEmoji {
  display: block;
}
.PrimaryCardFormEmojiCheck .ant-checkbox.ant-checkbox-checked + span .CryEmoji {
  display: none;
}
.PrimaryCardFormEmojiCheck .ant-checkbox {
  opacity: 0;
}
.PaginationContainer {
  width: 100%;
  float: left;
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.PaginationLabel {
  font-size: 14px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.85);
}
.PaginationContainer .ant-pagination-item {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
  border-color: transparent;
  width: 28px;
  height: 28px;
  margin: 0;
  margin-right: 5px;
  min-width: inherit;
}
.PaginationContainer .ant-pagination-item.ant-pagination-item-active {
  background-color: #fff;
  border-color: #1890ff;
  color: #1890ff;
}
.PaginationContainer .ant-pagination-prev .ant-pagination-item-link,
.PaginationContainer .ant-pagination-next .ant-pagination-item-link {
  border-color: transparent;
  background-color: transparent;
  line-height: 10px;
  width: 28px;
  height: 28px;
  margin: 0;
}
.PaginationContainer .ant-pagination-disabled,
.PaginationContainer .ant-pagination-disabled:hover,
.PaginationContainer .ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
  min-width: inherit;
}
.PaginationContainerInner {
  display: flex;
  align-items: center;
}
.cardCheckUncheckContainer {
  margin-top: -52px;
}
.cardCheckUncheckContainer .cardCheck {
  color: #1c8efa;
}
.cardCheckUncheckContainer .cardUncheck {
  color: #d9d9d9;
}
.primaryCard .cardCheckUncheckContainer .cardCheck {
  display: none;
}
.primaryCard.active .cardCheckUncheckContainer .cardCheck {
  display: block;
}
.primaryCard.active .cardCheckUncheckContainer .cardUncheck {
  display: none;
}
.primaryCard1 .cardCheckUncheckContainer .cardCheck {
  display: none;
}
.primaryCard1.active .cardCheckUncheckContainer .cardCheck {
  display: block;
}
.primaryCard1.active .cardCheckUncheckContainer .cardUncheck {
  display: none;
}
.text-success label {
  color: #28a745 !important;
}
.text-danger label {
  color: #dc3545 !important;
}
.CardContainerMainTitle {
  width: 100%;
  float: left;
  font-size: 13px;
  color: #000000;
  padding: 5px 0;
  font-family: "Roboto", sans-serif;
}
.ExecutedSwitchContainer .ant-switch {
  background-color: #df2b2b;
}
.ExecutedSwitchContainer .ant-switch-checked {
  background-color: #1890ff;
}
.PlayerprimaryCard .CardUserImg {
  margin-left: 0;
}
.PlayerprimaryCard .Soph {
  background-color: #fff;
  border: solid 1px #526b84;
  height: 100%;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 91% */

  text-align: center;

  /* Foundation/Gray/Dark Gray */

  color: #526b84;
}
.PlayerCardModal .ant-modal-header,
.PlayerCardModal .ant-modal-footer,
.PlayerCardModal .ant-modal-close-x {
  display: none;
}
.PlayerCardModal .ant-modal-body {
  padding: 0;
}
.SubmitCancelBtnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.SubmitCancelBtnContainer .ant-btn {
  min-width: 45%;
}
._loading_overlay_overlay {
  top: 121px !important;
}
._loading_overlay_spinner svg circle {
  stroke: #a22f2e !important;
}
._loading_overlay_content {
  color: #a33030 !important;
  font-size: 25px;
  font-weight: 700;
}
.loader_home {
  pointer-events: none;
  cursor: not-allowed;
  width: 100%;
  float: left;
  /* mix-blend-mode: overlay; */
  padding: 10px 0;
  background-blend-mode: overlay;
  opacity: 0.3;
}
.PrimaryCardForm.ant-form .ant-form-item-label > label {
  margin-right: 5px;
}
.PrimaryCardForm.ant-form .ant-radio-group {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.PlayerNumberInput .ant-form-item-label > label::after {
  display: none;
}
.PlayerNumberInput .ant-form-item-label {
  margin-right: 10px;
}
.PlayerNumberInput {
  padding-top: 10px;
}
.play_input .ant-input-number {
  width: 79px !important;
}

.play_input .ant-form-item-explain-error {
  font-size: 14px !important;
  margin-left: -129px !important;
}

.ant-table-thead > tr > th,
td.ant-table-cell {
  font-size: 12px !important;
}

@media screen and (max-width: 1100px) {
  .ant-table-thead > tr > th,
  td.ant-table-cell {
    font-size: 10px !important;
  }
}
th.ant-table-cell {
  border: none !important;
}

.ant-input-number-focused .ant-input-number-handler-wrap,
.ant-input-number-handler-wrap {
  opacity: 1 !important;
}

.ant-input-number-handler-wrap span.anticon {
  font-size: 12px !important;
}
.eva-table .ant-input-number.ant-input-number-borderless {
  width: 50px;
}
.eva-table span.ant-input-number-handler.ant-input-number-handler-down,
.eva-table span.ant-input-number-handler.ant-input-number-handler-up {
  border: none !important;
}
.eva-table button.ant-switch {
  background: #a40e17;
  border-radius: 8px;
}
.eva-table .ant-switch.ant-switch-checked {
  background-color: #1790FF;
}
.eva-table .ant-switch .ant-switch-handle {
  top: 3px;
  width: 16px;
  height: 16px;
}
.eva-table .ant-radio-group {
  display: flex;
  border-radius: 0px 8px 8px 0px;
  justify-content: center;
}
.eva-table .ant-radio-group label {
  background: #a9b6c238;
  border: none !important;
  padding: 0 10px;
}
.eva-table .ant-radio-group label.x-active,
.eva-table .ant-radio-group label.x-active:hover,
.eva-table .ant-radio-group label.x-active:focus {
  background: #A40E17 !important; 
  border: none !important;
}

.eva-table .ant-radio-group label.x-actived, .eva-table .ant-radio-group label.x-actived:hover,
.eva-table .ant-radio-group label.x-actived:focus {
     background: #A40E17 !important; 
     border: none !important; 
}
.eva-table .ant-radio-group label.onefive-actived, .eva-table .ant-radio-group label.onefive-actived:hover,
.eva-table .ant-radio-group label.onefive-actived:focus {
     background: #BCECB5 !important; 
     border: none !important; 
}
.eva-table .ant-radio-group label.grey, .eva-table .ant-radio-group label.grey:hover,
.eva-table .ant-radio-group label.grey:focus {
     background: #526B84 !important; 
     border: none !important; 
}
.eva-table .ant-radio-group label.yellow, .eva-table .ant-radio-group label.yellow:hover,
.eva-table .ant-radio-group label.yellow:focus {
     background: #E2B517 !important; 
     border: none !important; 
}

.eva-table .ant-radio-group label.x-actived > span > img, .eva-table .ant-radio-group label.x-active > span > img, .eva-table .ant-radio-group label.grey > span > img {
    filter: brightness(0) invert(1) !important;  
    border: none !important;
}

.eva-table .ant-radio-group label.onefive-actived > span > img, .eva-table .ant-radio-group label.onefive-active > span > img, .eva-table .ant-radio-group label.yellow > span > img{
     filter: brightness(10%) !important;  
    border: none !important;
}



/* .eva-table .ant-radio-group label > span > .x-active > img {
  filter: brightness(100%) !important;
}
  */

.eva-table .ant-radio-group label.one-active:hover,
.eva-table .ant-radio-group label.one-active:focus, .eva-table .ant-radio-group label.one-active {
  background: #78BF6E !important; 
  border: none !important;
}
.eva-table .ant-radio-group label.onefive-active:hover,
.eva-table .ant-radio-group label.onefive-active:focus, .eva-table .ant-radio-group label.onefive-active {
  background-color:#BCECB5 !important ;
}


.eva-table .ant-radio-group label.x-active > span {
  color: #ffffff !important;
}

.eva-table .ant-radio-group label.one-active > span, .eva-table .ant-radio-group label.onefive-active > span  {
  color: #0F1A24 !important;
}
.eva-table .ant-radio-group label.one-active > span > span, .eva-table .ant-radio-group label.onefive-active > span > span{
  color: #0F1A24 !important;
}

.eva-table .ant-radio-group label.one-active > span > img, .eva-table .ant-radio-group label.onefive-active > span > img, .eva-table .ant-radio-group label.yellow > span > img{
   filter: brightness(0) !important;
}



.eva-table .ant-radio-group label.x-inactive{
  background-color: #F2F2F7 !important;
}

/* .eva-table .ant-radio-group > .ant-radio-button-wrapper-checked > label{
  background-color: red !important;
} */

.eva-table .ant-radio-group label.one-inactive, .eva-table .ant-radio-group label.onefive-inactive{
  background-color: #F2F2F7 !important;
}

.eva-table .ant-radio-group label.x-inactive > span {
  color: #A9B6C2 !important;
}

.eva-table .ant-radio-group label.bad{
    background: #D1D1D6 !important;
}

/* .eva-table .ant-radio-group label.bad:active{
 
} */

.eva-table .ant-radio-group label.active.good,
.eva-table .ant-radio-group label.good:focus,
.eva-table .ant-radio-group label.good:hover,
.eva-table .ant-radio-group label.good:active {
  background: #93D789;
  color: #0F1A24 !important;
   border: none !important; 
}

/* .eva-table .ant-radio-group label.good:focus > span > img,
.eva-table .ant-radio-group label.good:hover > span > img,
.eva-table .ant-radio-group label.mid:hover > span > img,
.eva-table .ant-radio-group label.mid:active > span > img,
.eva-table .ant-radio-group label.mid:focus > span > img,  */

.eva-table .ant-radio-group label.active.mid,
.eva-table .ant-radio-group label.mid:hover,
.eva-table .ant-radio-group label.mid:active,
.eva-table .ant-radio-group label.mid:focus {
  background: #93D789;
  color: #0F1A24 !important;
}

.eva-table .ant-radio-group label.active.mid span {
  color: #ffffff !important;
}
.eva-table .ant-radio-group label.active.bad span {
  color: #ffffff !important;
}
.eva-table .ant-radio-group label.active.good span {
  color: #ffffff !important;
}

.eva-table .ant-radio-group label span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  /* Foundation/Gray/Gray */

  color: #a9b6c2;
}

.row-disabled {
  opacity: 0.4;
}

.row-disabled button.ant-switch,
.row-disabled .ant-radio-group label {
  cursor: not-allowed;
  background: #80808033;
}

/* play info table head */
.playinfotable .ant-table-thead > tr > th {
  align-items: center;
  background: #f3f5f7 !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 10px !important;

  color: #526b84 !important;
}

.table-action {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  padding: 0 5%;
}

.table-action .ant-btn {
  border: none !important;
  outline: none !important;
  border-radius: 4px;
}
th.ant-table-cell {
  color: #526b84 !important;
}
.table-action button {
  width: 45% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.table-action button:hover {
  background-color: #e8f4ff !important;
}
.table-action button .eye-img {
  width: 70% !important;
}
.table-action button .reset-img {
  width: 70% !important;
}

/* play info table body */
.playinfotable .ant-table-tbody > tr > td {
  font-size: 10px !important;
  background-color: #ffffff !important;
  color: #0f1a24 !important;
  padding: 0 1px !important;
}
.playinfotable .ant-table-tbody > tr > th {
  padding: 0 1px !important;
}

@media screen and (min-width: 1440px) {
  .playinfotable .ant-table-thead > tr > th {
    font-size: 0.76vw !important;
    color: #526b84 !important;
  }

}

/* .ant-table-tbody{
  padding: 0 !important;
  background-color: red !important;
}
.ant-table-tbody > tr > th{
  padding: 0 !important;
  background-color: red !important;
} */

.depthchart .ant-table-tbody > tr > td,
.depthchart .ant-table-thead > tr > th {
  font-size: 10.5px !important;
}

@media screen and (min-width: 1440px) {
  .depthchart .ant-table-tbody > tr > td,
  .depthchart .ant-table-thead > tr > th {
    font-size: 0.79vw !important;
  }
}

/* :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table.ant-table-middle .ant-table-title, :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table.ant-table-middle .ant-table-footer, :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table.ant-table-middle tfoot>tr>th, :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table.ant-table-middle tfoot>tr>td {
  padding: 8px 12px !important;
} */

/* :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper tfoot>tr>th {
  position: relative;
  padding: 0px 0px !important;
  overflow-wrap: break-word;
} */


.playinfotable .ant-table-thead > tr > th, .playinfotable .ant-table-tbody > tr > td {
  height: 30px !important;
  border-right: none !important;
  font-weight: bold !important;
  padding: 0 1px !important;
}

/* .playinfotable .ant-table-thead > tr > th, .playinfotable .ant-table-tbody > tr > td {
  height: 30px !important;
  border-right: none !important;
} */

.playinfotable th.ant-table-cell {
  border: none !important;
}

.playinfotable thead .ant-table-thead {
  height: 10px !important;
}

/* Hide the table initially */
.eva-table {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease, opacity 0.6s ease;
  opacity: 0; 
}

/* When active (after click) */
.eva-table.active {
  max-height: 1000px; /* Or something big enough to reveal it */
  opacity: 1;
}

/* Blink/glow animation for the head */
@keyframes blinkGlow {
  0%, 100% {
    box-shadow: 0 0 5px #00ffcc;
    background-color: #1a1a1a;
  }
  50% {
    box-shadow: 0 0 15px #00ffcc;
    background-color: #222;
  }
}

.eva-table-head  { 
  cursor: pointer; 
}

.eva-table-head.attention {
  animation: blinkGlow 1.2s ease-in-out infinite;
  cursor: pointer;  
}
